<template>
	<div>
		<ContentHeader title="Tindakan" url="/tindakan" subTitle="Data Voucher" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row">
								<h3 class="card-title">Penerbitan Voucher Promo</h3>
							</div>
							<div class="card-body">
								<form @submit.prevent="prosesVoucher">
									<div class="row">
										<div class="col-10">
											<div class="px-lg-2">
												<div class="form-group">
								                   <label for="nama">Nama Promo <span class="text-danger">*</span></label>
								                   <input type="text" class="form-control" autocomplete="off" required="" id="nama" name="nama" placeholder="" v-model="voucher.nama" @keyup="kodeVoucherByNama(voucher.nama)">
									           </div>
											</div>	
										</div>
										<div class="col-2"></div>	
									</div>
									<div class="row">
										<div class="col-10">
											<div class="px-lg-2">
												<div class="form-group">
								                   <label for="kode_voucher">Kode Voucher</label>
								                   <p class="form-control">{{ kode_voucher_awal }}{{ kode_voucher_akhir }}</p>
									           </div>
											</div>	
										</div>
										<div class="col-2"></div>	
									</div>
									<div class="row">
										<div class="col">
											<div class="px-lg-2">
												<div class="form-group">
								                   <label for="jumlah">Sejumlah <span class="text-danger">*</span></label>
								                   <div class="input-group mb-3">
								                      <input type="number" class="form-control" required="" id="jumlah" name="jumlah" placeholder="Jumlah" v-model="voucher.jumlah" @keyup="kodeVoucherByJumlah">
								                      <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Lembar</div>
									                  </div>
									               </div>
									           </div>
											</div>	
										</div>
										<div class="col"></div>	
									</div>
									<div class="row">
										<div class="col">
											<div class="px-lg-2">
												<div class="form-group">
								                   <label for="nominal">Nominal <span class="text-danger">*</span></label>
								                   <div class="input-group mb-3">
								                      <div class="input-group-prepend">
									                    <div class="btn btn-secondary">{{ nominal }}</div>
									                    <button type="button" class="btn btn-secondary btn-flat dropdown-toggle dropdown-icon" data-toggle="dropdown">
									                      <span class="sr-only">Toggle Dropdown</span>
									                    </button>
									                    <div class="dropdown-menu" role="menu">
									                      <a class="dropdown-item" href="#" @click="changeNominal()">Rupiah</a>
									                      <a class="dropdown-item" href="#" @click="changeNominal()">Persen (%)</a>
									                    </div>
									                  </div>
								                      <input type="number" class="form-control" required="" id="nominal" name="nominal" placeholder="Nominal" step="0.01" min="0" lang="en" v-model="voucher.nominal" @keyup="limitValueNominal(voucher.nominal)" >
									               </div>
									           </div>
											</div>	
										</div>
										<div class="col"></div>	
									</div>
									<div class="row">
										<div class="col-8">
											<div class="px-lg-2">
												<div class="form-group">
								                   <label for="nama">Masa Berlaku <span class="text-danger">*</span></label>
								                   <input type="date" class="form-control" required="" id="masa_berlaku" name="masa_berlaku" placeholder="Jumlah" v-model="voucher.masa_berlaku">
									           </div>
											</div>	
										</div>
										<div class="col-4"></div>	
									</div>
									<div class="px-lg-2 mt-3">
										<button type="submit" class="btn btn-primary">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<div class="card">
	              <div class="card-header">
	                <h3 class="card-title"><div>Setup Voucher</div></h3>

	                <div class="card-tools">
	                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
	                    <i class="fas fa-minus"></i>
	                  </button>
	                </div>
	              </div>
	              <div class="card-body" style="display: block;">
	                <table class="table table-bordered table-hover">
	                  <thead>
	                    <tr>
	                      <th scope="col">KODE</th>
	                      <th scope="col">NOMINAL</th>
	                      <th scope="col">MASA BERLAKU</th>
	                      <th scope="col">ACTION</th>
	                    </tr>
	                  </thead>
	                  <tbody>
	                    <tr v-for="row in dataVoucher" :key="row.id">
	                      <td scope="row">{{ row.kode_voucher }}</td>
	                      <td>{{ format_nominal(row.nominal)  }}</td>
	                      <td>{{ format_tanggal(row.masa_berlaku.slice(0, 10)) }}</td>
	                      <td class="text-sm">
					      	<div class="btn-group">
			                    <button type="button" class="btn btn-sm btn-info text-sm">Proses</button>
			                    <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
			                      <span class="sr-only"></span>
			                    </button>
			                    <div class="dropdown-menu" role="menu" style="">
			                      <router-link :to="`/edit-data-voucher/${row.id}`" class="dropdown-item text-sm">Edit</router-link>
			                      <div class="dropdown-divider"></div>
			                      <button @click="hapusDataVoucher(row.id)" class="dropdown-item text-danger">Hapus</button>
			                    </div>
			                  </div>
					      </td>
	                    </tr>
	                  </tbody>
	                </table>
	              <p class="text-center mt-2">{{ cekDataVoucher }}</p>
	              </div>
	              <!-- /.card-body -->
	              <div class="card-footer" style="display: block;">
	                
	              </div>
	              <!-- /.card-footer-->
	            </div>

			</div>
		</div>
	</div>
</template>

<script>
	import ContentHeader from '@/components/ContentHeader2'

	import Swal from 'sweetalert2'
	import { computed, reactive, ref, onMounted } from 'vue'
	import router from '@/router'
	import store from '@/store'
	import axios from 'axios'
	import number_format from '@/helper.js'
	import format_tanggal from '@/format_tanggal.js'
	import format_nominal from '@/format_nominal.js'

	export default{
		components: {
			ContentHeader: ContentHeader
		},
		setup(){
			const user = computed(() => store.getters['auth/user'])
			const kode_voucher = ref([])

			const { voucher, prosesVoucher } = useTambahDataVoucher(user, kode_voucher)

			const { dataVoucher, cekDataVoucher, getVoucher, hapusDataVoucher } = useGetVoucher(user)

			const { nominal, changeNominal, limitValueNominal } = useInputPersen(voucher)


			const kode_voucher_awal = ref('')
			const kode_voucher_akhir = ref('')
			const kode_nama = ref('')
			const tahun = ref()
			const bulan = ref()

			const date = new Date()

			const kodeVoucherByNama = (value) => {
				console.log(value)
				if (value == null || value == '') {
					console.log('masuk')
					kode_voucher_awal.value = ''
					kode_voucher_akhir.value = ''
					kode_voucher.value = []
					voucher.jumlah = 0
				} else {
					console.log('keluar')
					kode_nama.value = value.split(" ");
					tahun.value = date.getFullYear()
					tahun.value = tahun.value.toString().slice(2, 4)
					bulan.value = date.getMonth() + 1
					bulan.value = (bulan.value > 9) ? bulan.value : '0' + bulan.value;

					kode_nama.value = kode_nama.value.map((value) => {
						return value.slice(0, 1).toUpperCase() 
					})
					.reduce((acc, value) => {
						return `${acc}${value}`
					})

					kode_voucher_awal.value = `${kode_nama.value}${tahun.value}${bulan.value}001`

					kode_voucher_akhir.value = ''
					kode_voucher.value = []
					voucher.jumlah = 0
				}
				
			}

			const kodeVoucherByJumlah = () => {
				let jumlah = parseInt(voucher.jumlah)
				kode_voucher.value = []

				if (jumlah != 0 || jumlah != null) {
					for(let i = 1; i <= jumlah; i++){
						let no_urut = (i > 9) ? (i > 99) ? i : '0' + i : '00' + i

						kode_voucher.value.push(`${kode_nama.value}${tahun.value}${bulan.value}${no_urut}`)

						if (i == jumlah) {
							kode_voucher_akhir.value = ` s/d ${kode_nama.value}${tahun.value}${bulan.value}${no_urut}`
						}
					}
				}


				console.log(kode_voucher.value)

			}

			onMounted(() => {
				getVoucher()
			})

			return {
				voucher,
				prosesVoucher,
				dataVoucher,
				cekDataVoucher,
				getVoucher,
				hapusDataVoucher,
				number_format,
				format_tanggal,
				nominal,
				changeNominal,
				limitValueNominal,
				format_nominal,
				kode_voucher_awal,
				kode_voucher_akhir,
				kodeVoucherByNama,
				kodeVoucherByJumlah,
				kode_voucher
			}
		}
	}

	function useTambahDataVoucher (user, kode_voucher) {
		const voucher = reactive({
			"cabang_id" : user.value.cabang_id,
			"nama" : '',
			"kode_voucher" : kode_voucher,
			"telah_digunakan" : null,
			"jumlah" : 0,
			"nominal" : 0,
			"masa_berlaku" : "",
		})

		const prosesVoucher = () => {
			console.log(voucher)
			axios.post('api/voucher/create', voucher)
			.then((response) => {
				console.log(response)
				Swal.fire({
				  title: 'Berhasil!',
				  text: 'Voucher Berhasil Dibuat',
				  icon: 'success',
				  showConfirmButton: false,
				  timer: 1500
				})	
				router.push('/tindakan')
			})
			.catch((error) => {
				Swal.fire({
				  title: 'Gagal!',
				  text: 'Gagal Membuat Voucher',
				  icon: 'error',
				  showConfirmButton: false,
				  timer: 1500
				})	
				console.log(error)
			})
		}

		return {
			voucher,
			prosesVoucher
		}
	}


	function useGetVoucher (user) {
	  const dataVoucher = ref([])
	  const cekDataVoucher = ref()
	  console.log(dataVoucher.value)

	  const getVoucher = async () => {
	    let response = await axios.get('api/voucher/get/' + user.value.cabang_id)
	    
	    if (response.data == 'kosong') {
	      cekDataVoucher.value = 'Data Masih Kosong'
	      dataVoucher.value = null
	    } else {
	      cekDataVoucher.value = ''
	      dataVoucher.value = response.data
	    }
	  }


		const hapusDataVoucher = (id) => {
		axios.delete(`api/voucher/destroy/${id}`)
			.then((response) => {
				Swal.fire({
				  title: 'Berhasil!',
				  text: 'Berhasil menghapus data',
				  icon: 'success',
				  confirmButtonText: 'Ok'
				})	
				console.log(response.data)
			})
			.catch((error) => {
				Swal.fire({
				  title: 'Gagal!',
				  text: 'Gagal menghapus data',
				  icon: 'success',
				  confirmButtonText: 'Ok'
				})
				console.log(error)
			})

			getVoucher()
		}

	  return {
	    dataVoucher,
	    cekDataVoucher,
	    getVoucher, 
	    hapusDataVoucher
	  }  
	}


	function useInputPersen (voucher) {
		const nominal = ref('Rp.')

		const changeNominal = () => {
			if (nominal.value == 'Rp.') {
				nominal.value = 'Persen (%)'
				voucher.nominal = 0
			} else if (nominal.value == 'Persen (%)') {
				nominal.value = 'Rp.'
				voucher.nominal = 0
			}
		}

		const limitValueNominal = (value) => {
			console.log(`${voucher.nominal}`)
			if (nominal.value == 'Persen (%)' && value > 100) {
				voucher.nominal = 100
			} else if (nominal.value == 'Persen (%)' && value < 0) {
				voucher.nominal = 0
			}
		}

		return {
			nominal,
			changeNominal,
			limitValueNominal
		}
	}
	
</script>